<template>
    <div class="container">
        <div id="accordion1" class="mb-4 animated-fast fadeInLeft">
            <div class="card b0 mb-2 card-default" v-for="(entry, index) in entries" :key="index">
                <div class="card-header" v-b-toggle="index">
                    <h4 class="card-title">
                        <a class="text-inherit">
                            <small>
                                <em class="far fa-plus text-white mr-2"></em>
                            </small>
                            <span>{{entry.question}}</span>
                        </a>
                    </h4>
                </div>
                <b-collapse :id="index">
                    <div class="card-body">
                        <p>{{entry.answer}}</p>
                    </div>
                </b-collapse>
            </div>
        </div>
    </div>
</template>
<style>
    .card-header {
        cursor: pointer;
    }
</style>
<script>
    export default {
        components: {},
        data() {
            return {
                entries: {}
            }
        },
        mounted() {
            this.entries = this.$i18n.t('support.component.faq.categories.' + this.$route.params.category + '.entries');
        },
        methods: {}
    }


</script>